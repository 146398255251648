.popup-container {
    display: flex;
    flex-direction: row;
    border: 1px solid $theme-color;
    padding:1rem;
    align-items: center;
    img {
      width: 11rem;
      padding-bottom: 2rem;
    }
  
    @media #{$xs-layout} {
      flex-direction: column;
    }
  }
  
  .popup-content {
    @media #{$xx-layout} {
      width: 40% !important;
    }
    @media #{$xs-layout} {
      overflow-y: auto;
      width: 90% !important;
      #name {
        margin-top: 1rem;
      }
      img {
        display: none;
      }
    }
  }
  
  .form-container {
    width: 100%;
  
    @media #{$xs-layout} {
      form {
        padding-right: 1rem;
        padding-bottom: 2rem;
      }
      img {
        display: none;
      }
      width: 100%;
      button {
        width: 90%!important;
        margin-top: 2rem!important;
        margin-bottom: 2rem!important;
        font-size: 0.5rem!important;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    h3 {
      text-align: center;
      color: $theme-color;
      font-size: 24px;
      line-height: 38px;
      padding: 1rem;
    }
    button {
      background-color: $theme-color;
      border: medium none;
      color: #fff;
      cursor: pointer;
      font-weight: 500;
      padding: 10px 30px;
      letter-spacing: 1px;
      text-transform: uppercase;
      z-index: 9;
      width: 100%;
      font-size: 0.8rem;
      width: 50%;
      margin-top: 2rem;
      margin-bottom: 2rem;
      &:hover {
        background-color: $secundary-color;
      }
    }
  
    input {
      background: #ffffff;
      border: none;
      padding-left: 0px;
      border-bottom: 2px solid $secundary-color;
      height: 33px;
      font-size: 17px;
      color: #333;
      width: 100%;
      padding-bottom: 0.5rem;
    }
    form {
      width: 100%;
      padding-left: 2rem;
      padding-top: 2rem;
    }
  }
  
  .popup-img {
    width: 100%;
    height: 34rem;
  
    background-position: center center;
  
    background-repeat: no-repeat;
  
    background-attachment: inherit;
  
    background-size: contain;
  
    @media #{$xs-layout} {
      height: 21rem;
    }
  }