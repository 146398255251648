/*----- 2. Header style ----*/
.header-bar-top {
    @media #{$xs-layout} {
        padding: 1rem;
        height: 3rem;
        text-align: center;
        margin-bottom:1rem;
    }
    height: 50px;
    background: $theme-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span {
      font-size: 15px;
      font-family: $montlight;
        color:#fff;
        @media #{$xs-layout} {
          font-size: 0.6rem!important;
          text-align: initial!important;
        }
    }
  }

.header-area {
    position: relative;
}

.sticky-bar.stick {
	animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	background-color: #fff;
	border-bottom: 0 solid #4a90e2;
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	left: 0;
    transition: all .3s ease 0s;
    
    .center-menu-logo{
        display: none;
        @media #{$md-layout}{
		    display: block;
        }
        @media #{$xs-layout}{
            display: block;
        }
    }

    .header-right-wrap-white{
        .same-style {
            > a {
                color: #000;
            }
            
            &.cart-wrap, &.header-compare, &.header-wishlist, &.account-setting, &.header-search {
                > button, & > a {
                    color: #000;
                        span{
                        &.count-style {
                            background-color: #000;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.header-padding-1 {
    padding: 0 80px;
    @media #{$xl-layout}{
		padding: 0 15px;
	}
    @media #{$lg-layout}{
		padding: 0 30px;
	}
    @media #{$md-layout}{
		padding: 0 40px;
	}
    @media #{$xs-layout}{
		padding: 0 15px;
	}
}
.header-res-padding {
    @media #{$md-layout}{
        padding: 0px 0 15px
    }
    @media #{$xs-layout}{
        padding: 0px 0 15px;
        .stick{
            padding: 20px 0 15px;
        }
    }
}
.stick {
    
    @media #{$xs-layout}{
            padding: 20px 0 15px;
    }
}

.logo {
    @media #{$md-layout}{
        margin-top: 0px;
    }
    @media #{$xs-layout}{
        margin-top: 0px;
    }
    &.logo-hm5 {
        margin-top: 41px;
        a.sticky-block {
            display: none;
          @media #{$md-layout, $xs-layout}{
              display: block;
            } 
        }
        a.sticky-none {
            display: block;
            @media #{$md-layout, $xs-layout}{
              display: none;
            } 
        }
        @media #{$md-layout}{
            margin-top: 0px;
            text-align: left !important;
        }
        @media #{$xs-layout}{
            margin-top: 0px;
            text-align: left !important;
        }
    }
}

.stick {
   .logo-hm5 {
        a.sticky-block {
            display: block;
        }
        a.sticky-none {
            display: none;
        }
    } 
}

.main-menu {
    nav {
        ul {
            display: block;
            text-align: center;
            margin-top: -5px;
                li{
                    display: inline-block;
                    padding: 0 15px;
                    position: relative;
                    ul.submenu {
                        background: #fff none repeat scroll 0 0;
                        box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
                        display: block;
                        left: 0;
                        padding: 20px 0 22px;
                        position: absolute;
                        top: 100%;
                        transform: rotateX(90deg);
                        transform-origin: center top 0;
                        transition: all 0.5s ease 0s;
                        visibility: hidden;
                        width: 210px;
                        z-index: 999;
                        li {
                            display: block;
                            padding: 0 20px;
                            margin-left: 0;
                            a {
                                color: #333;
                                display: block;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 32px;
                                text-align: left;
                                text-transform: capitalize;
                                padding-top: 0;
                                &:hover {
                                    color: $theme-color;
                                }
                                &::before {
                                    background: $theme-color none repeat scroll 0 0;
                                    border-radius: 50%;
                                    content: "";
                                    height: 7px;
                                    left: 15px;
                                    opacity: 0;
                                    position: absolute;
                                    top: 13px;
                                    transition: all 0.3s ease-in-out 0s;
                                    width: 7px;
                                    z-index: 999;
                                }
                            }
                            &:hover a::before {
                                opacity: 1;
                            }
                            &:hover a {
                                padding-left: 12px;
                            }
                        }
                    }
                    ul.mega-menu {
                        background-color: #fff;
                        display: block;
                        left: 0;
                        padding: 40px;
                        position: absolute;
                        text-align: left;
                        top: 100%;
                        transform: rotateX(90deg);
                        transform-origin: center top 0;
                        transition: all 0.5s ease 0s;
                        visibility: hidden;
                        width: 700px;
                        z-index: 999;
                        box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
                        @media #{$lg-layout} {
                            left: -75px;
                        }
                        > li {
                            display: inline-block;
                            float: left;
                            margin-left: 0;
                            padding: 0;
                            width: 33.33%;
                            ul {
                                li.mega-menu-title {
                                    float: inherit;
                                    width: 100%;
                                    a {
                                        color: #242424;
                                        margin: 0 0 10px;
                                        text-transform: uppercase;
                                        font-size: 14px;
                                        font-weight: 500;
                                        &::before {
                                            display: none
                                        }
                                    }
                                    &:hover a {
                                        padding-left: 0px;
                                    }
                                }
                                li {
                                    display: block;
                                    float: inherit;
                                    padding: 0px;
                                    a {
                                        color: #333;
                                        display: block;
                                        font-size: 13px;
                                        font-weight: 400;
                                        line-height: 32px;
                                        text-align: left;
                                        text-transform: capitalize;
                                        padding-top: 0px;
                                        &:hover {
                                            color: $theme-color;
                                        }
                                        &::before {
                                            background: $theme-color none repeat scroll 0 0;
                                            border-radius: 50%;
                                            content: "";
                                            height: 7px;
                                            left: 0px;
                                            opacity: 0;
                                            position: absolute;
                                            top: 13px;
                                            transition: all 0.3s ease-in-out 0s;
                                            width: 7px;
                                            z-index: 999;
                                        }
                                    }
                                    &.mega-menu-img {
                                        &:hover a {
                                            padding-left: 0px;
                                        }
                                        a{
                                           &::before {
                                                display: none;
                                            } 
                                        }
                                    }
                                    &:hover a::before {
                                        opacity: 1;
                                    }
                                    &:hover a {
                                        padding-left: 12px;
                                    }
                                }
                            }
                        }
                        &.mega-menu-padding {
                            padding: 40px 40px 36px 40px;
                            @media #{$lg-layout}{
                                left: 0px;
                            }
                        }
                    }
                &:hover > ul.submenu {
                    transform: rotateX(0deg);
                    visibility: visible;
                }
                &:hover > ul.mega-menu {
                    transform: rotateX(0deg);
                    visibility: visible;
                }
                @media #{$lg-layout}{
                    padding: 0 7px;
                }
                @media #{$xl-layout}{
                    padding: 0 10px;
                }
                > a{
                    font-size: 15px;
                    color: #555252;
                    font-weight: 500;
                    letter-spacing: .8px;
                    display: inline-block;
                    line-height: 94px;
                    i {
                        font-size: 15px;
                        margin-left: 1px;
                        position: relative;
                        top: 0px;
                    }
                }
                &:hover a {
                    color: $theme-color;
                }
            }
        }
    }
    &.menu-white {
        nav{
            ul {
                text-align: left;
                li{
                    padding: 0 30px 0 0;
                    @media #{$xx-layout}{
                        padding: 0 22px 0 0;
                    }
                    @media #{$xl-layout}{
                        padding: 0 14px 0 0;
                    }
                    @media #{$lg-layout}{
                        padding: 0 14px 0 0;
                    }
                    > a{
                        color: #ffffff;
                        line-height: 112px;
                    }
                }
            }
        }
    }
}
.stick .main-menu.menu-white nav ul li a{
    color: #555252;
    &:hover {
        color: $theme-color;
    }
}

.stick .main-menu nav ul li a {
	line-height: 75px;
}

.stick .logo ,
.stick .header-right-wrap {
    @media #{$md-layout}{
        margin-top: 0px;
    }
    @media #{$xs-layout}{
        margin-top: 0px;
    }
    @media #{$md-layout}{
        margin-top: 0px;
    }
}
.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content ,
.stick .header-right-wrap .same-style .account-dropdown {
	top: 160%;
    @media #{$xs-layout}{
		top: 100%;
        margin-top: 14px;
	}
    @media #{$md-layout}{
		top: 100%;
        margin-top: 14px;
	}
}





.header-right-wrap {
    display: flex;
    justify-content: flex-end;
    @media #{$md-layout}{
        margin-top: 0;
    }
    @media #{$xs-layout}{
        margin-top: 2px;
    }
    .same-style {
        margin-left: 14px;
        position: relative;
        @media #{$lg-layout}{
            margin-left: 10px;
        }
        @media #{$md-layout}{
            margin-left: 15px;
        }
        @media #{$xs-layout}{
            margin-left: 20px;
        }
        > a, button{
            color: #000;
            font-size: 23px;
        }
        > button{
            background: none;
            border: none;
        }
        &:first-child {
            margin-left: 0px;
        }
        &:hover > a, &:hover > button {
            color: $theme-color;
        }
        .account-dropdown {
            background: #fff none repeat scroll 0 0;
            box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
            right: 0;
            padding: 8px 12px 10px;
            position: absolute;
            text-align: left;
            top: 190%;
            width: 125px;
            z-index: 220;
            visibility: hidden;
            opacity: 0;
            transform: rotateX(90deg);
            transform-origin: center top 0;
            transition: all 0.5s ease 0s;
            &.active{
                visibility: visible;
                opacity: 1;
                transform: rotateX(0deg);
            }
            @media #{$xs-layout}{
                top: 100%;
                margin-top: 14px;
            }
            @media #{$md-layout}{
                top: 100%;
                margin-top: 14px;
            }
            @media #{$lg-layout}{
                left: -40px;
            }
            @media #{$xl-layout}{
                left: -34px;
            }
            ul {
                li{
                    a {
                        display: block;
                        font-size: 13px;
                        line-height: 30px;
                        padding: 0;
                        text-transform: capitalize;
                        color: #000;
                        &:hover {
                            padding-left: 5px;
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    .same-style.account-setting {
        > a, button{
            font-size: 24px;
        }

        > button{
            background: none;
            border: none;

        }
    }
    .same-style.cart-wrap, .same-style.header-wishlist, .same-style.header-compare {
        margin-left: 15px;
        @media #{$lg-layout}{
            margin-left: 15px;
        }
        @media #{$md-layout}{
            margin-left: 20px;
        }
        @media #{$xs-layout}{
            margin-left: 20px;
        }
        button, & > a {
            background: transparent;
            border: none;
            padding: 0;
            color: #000;
            font-size: 24px;
            position: relative;
            display: inline-block;
            span.count-style {
                position: absolute;
                top: -9px;
                right: -14px;
                background-color: $theme-color;
                color: #fff;
                display: inline-block;
                width: 21px;
                height: 21px;
                border-radius: 100%;
                line-height: 21px;
                font-size: 12px;
                text-align: center;
                @media #{$xl-layout}{
                    right: -5px;
                }
            }
        }
        &:hover > button {
            color: $theme-color;
        }
        .shopping-cart-content{
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            opacity: 0;
            visibility: hidden;
            padding: 10px 35px 10px;
            position: absolute;
            right: 0;
            top: 190%;
            width: 340px;
            z-index: 9999;
            transform: rotateX(90deg);
            transform-origin: center top 0;
            transition: all 0.5s ease 0s;
            &.cart-visible, &.active  {
                opacity: 1;
                visibility: visible;
                transform: rotateX(0deg);
            }
            @media #{$xs-layout} {
                width: 295px;
                right: -52px;
                padding: 31px 15px 10px;
                overflow-y: auto;
                height: 300px;
                top: 100%;
                margin-top: 14px;
            }
            @media #{$md-layout} {
                overflow-y: auto;
                height: 300px;
                margin-top: 18px;
                top: 100%;
                margin-top: 14px;
            }
            @media #{$lg-layout} {
                overflow-y: auto;
            }
            ul {
                padding-top: 20px;
                max-height: 400px;
                overflow-y: auto;
                padding-right: 15px;
                margin-right: -15px;
                li {
                    border-bottom: 1px solid #ebebeb;
                    display: flex;
                    flex-wrap: nowrap;
                    margin-bottom: 20px;
                    padding-bottom: 15px;
                    position: relative;
                    .shopping-cart-img {
                        flex: 0 0 82px;
                        margin-right: 15px;
                    }
                    .shopping-cart-title {
                        h4 {
                            font-size: 15px;
                            line-height: 16px;
                            margin: 0;
                            margin-right: 20px;
                            a {
                                color: #000;
                                line-height: 1.2;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                        h6 {
                            font-size: 13px;
                            margin: 5px 0 8px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                    .shopping-cart-delete {
                        position: absolute;
                        top: 0;
                        right: 0;
                        a, button {
                            font-size: 16px;
                            color: $theme-color;
                            &:hover {
                                color: #333;
                            }
                        }
                    }
                }
            }
            .shopping-cart-total {
                h4 {
                    font-size: 14px;
                    margin-top: 17px;
                    margin-bottom: 17px;
                    span {
                        float: right;
                        
                    }
                }
            }
            .shopping-cart-btn {
                margin-top: 23px;
                a {
                    margin-bottom: 20px;
                    padding: 16px 40px 17px;
                    font-weight: 500;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
    &.header-right-wrap-white {
        margin-top: 40px;
        .same-style {
            > a {
                color: #fff;
            }
            
            &.cart-wrap, &.header-compare, &.header-wishlist, &.account-setting, &.header-search {
                > button, & > a {
                    color: #fff;
                        span{
                        &.count-style {
                            background-color: #fff;
                            color: #000;
                        }
                    }
                }
            }
        }

        @media #{$md-layout}{
          margin-top: 0; 
          
          .same-style {
              > a {
                  color: #000;
              }
              
              &.cart-wrap, &.header-compare, &.header-wishlist, &.account-setting, &.header-search {
                  > button, & > a {
                      color: #000;
                          span{
                          &.count-style {
                              background-color: #000;
                              color: #fff;
                          }
                      }
                  }
              }
          }
    
        }
        @media #{$xs-layout}{
          margin-top: 3px;
          .same-style {
              > a {
                  color: #000;
              }
              
              &.cart-wrap, &.header-compare, &.header-wishlist, &.account-setting, &.header-search {
                  > button, & > a {
                      color: #000;
                          span{
                          &.count-style {
                              background-color: #000;
                              color: #fff;
                          }
                      }
                  }
              }
          }
        }
    }
    .same-style.header-search {
        .search-content {
            background: #ffffff none repeat scroll 0 0;
            box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
            min-width: 300px;
            padding: 15px;
            position: absolute;
            right: 0;
            top: 193%;
            z-index: 99;
            visibility: hidden;
            opacity: 0;
            transform: rotateX(90deg);
            transform-origin: center top 0;
            transition: all 0.5s ease 0s;
            &.active{
                visibility: visible;
                opacity: 1;
                transform: rotateX(0deg);
            }
            @media #{$md-layout}{
                top: 154%;
            }
            @media #{$xs-layout}{
                right: -105px;
                top: 154%;
                min-width: 260px;
            }
            form {
                position: relative;
                input {
                    border: 1px solid #d3d3d3;
                    outline: medium none;
                    padding: 5px 72px 5px 15px;
                    transition: all 0.4s ease 0s;
                    width: 100%;
                    color: #000;
                    background: transparent;
                }
                .button-search {
                    background: $theme-color;
                    border-color: #ce9634;
                    color: #ffffff;
                    position: absolute;
                    right: 0;
                    text-transform: uppercase;
                    top: 0;
                    border: none;
                    height: 100%;
                    padding: 5px 20px 2px;
                    font-size: 20px;
                    line-height: 1;
                    transition: all 0.4s ease 0s;
                    &:hover {
                        background-color: #000;
                    }
                }
            }
        } 
    }
}
.stick {
    .same-style {
       &.header-search {
            .search-content {
                top: 160%;
            }
        } 
    }
}
.stick .header-right-wrap.header-right-wrap-white {
    margin-top: 22px;
    @media #{$md-layout}{
        margin-top: 0;
    }
    @media #{$xs-layout}{
        margin-top: 0;
    }
    .same-style {
        > a {
            color: #000;
            &:hover {
                color: $theme-color;
            }
        }
        &.cart-wrap {
            > button {
                color: #000;
                &:hover {
                    color: $theme-color;
                }
                span{
                    &.count-style {
                        background-color: #000;
                        color: #fff;
                    }
                }
            }
        }
    }
}


/* home 5 */

.wrapper {
    margin: 20px 20px;
    @media #{$md-layout}{
        margin-top: 0;
    }
    @media #{$xs-layout}{
        margin: 0;
    }
}





.mobile-aside-button{
    padding: 0;
    i{
        color:$theme-color;
    }
}




/* offcanvas mobile menu */

.header-mobile-navigation{
    padding: 20px 0;
  
    .mobile-navigation{
        .header-cart-icon{
            a{
                span{
                    left: 50%;
                }
            }
        }
    }
  }
  
  .offcanvas-widget-area{
    margin-bottom: 35px;
    margin-top: auto;
  
    @media #{$sm-layout}{
        margin-bottom: 30px;
    }
  }
  
  .off-canvas-contact-widget{
    margin-bottom: 20px;
  
    .header-contact-info{
        flex-basis: 33.33%;
        &__list{
            li{
                display: inline-block;
                margin-right: 25px;
                i{
                    font-size: 16px;
                    margin-right: 5px;
                }
    
                a{
                    &:hover{
                        color: $theme-color;
                    }
                }
    
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    
  }
  
  .offcanvas-mobile-menu{
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    max-width: 100%;
    
    height: 100vh;
    z-index: 999999999999;
    
    transform: translateX(100%);
    padding-left: 60px;
    transition: 0.6s;
    
  
    &.active{
        transform: translateX(0);
    }
  
    &.inactive{
        transform: translateX(calc(100% + 60px));
    }
  
  }
  
  .offcanvas-menu-close{
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 9;
    width: 60px;
    height: 65px;
    color: #fff;
    line-height: 70px;
    text-align: center;
    font-size: 30px;
    border: 1px solid $theme-color;
    padding: 0;
  
  
    // @media #{$sm-layout}{
    //     width: 50px;
    //     height: 50px;
    //     line-height: 55px;
    //     left: 10px;
    //     font-size: 25px;
    // }
  
    &:hover, &:focus{
        color: $white;
    }
    
    i{
        font-size: 40px;
        margin-top: 12px;
        line-height: 1;
        transition: 0.3s;
        transform: rotate(0);
    }
  
    &:hover{
        i{
            transform: rotate(-90deg);
        }
    }
  }
  
  .offcanvas-wrapper{
    overflow: auto;
    height: 100%;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    background-color: $white;
  }
  
  .offcanvas-mobile-search-area{
    background-color: #e6e6e6;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    z-index: 9;
    margin-left: 60px;
  
    input{
        width: 100%;
        font-size: 16px;
        display: block;
        padding: 9px 25px;
        color: #222;
        background: #e6e6e6;
        border: none;
  
        @media #{$sm-layout}{
            font-size: 14px;
            padding: 5px 15px;
        }
    }
  
    button{
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #aaa;
        padding: 0;
  
        i{
            font-size: 18px;
            line-height: 40px;
            
        }
    }
  }
  
  .offcanvas-inner-content{
    padding: 90px 35px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  
    @media #{$sm-layout}{
        padding: 70px 25px 0;
    }
  }
  
  .offcanvas-navigation{
    margin-bottom: 50px;
    & > ul{
         li{
            &.menu-item-has-children{
                     .sub-menu{
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        transition: 0.3s;
                    }
  
                    &.active{
                        & > .sub-menu{
                            height: 100%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
            }
            & > a{
                color: #111;
                font-weight: 600;
                padding: 10px 0;
                display: block;
                &:hover{
                    color: $theme-color;
                }
  
                @media #{$sm-layout}{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
  
    ul{
  
        &.sub-menu{
            margin-left: 25px;
            transition: 0.3s;
            & > li{
                & > a{
                    font-size: 13px;
                    font-weight: 400;
                    padding: 10px 0;
                    &:hover{
                        color: $theme-color;
                    }
  
                    @media #{$sm-layout}{
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
        }
        li{
  
  
          a{
            text-transform: uppercase;
          }
            
  
            &.menu-item-has-children{
                position: relative;
                display: block;
                a{
                    display: block;
                }
  
                &.active{
                    & > .menu-expand{
                        i{
                            &:before{
                                transform: rotate(0);
                            }
                        }
                    }
                }
  
                .menu-expand{
                    position: absolute;
                    right: auto;
                    left: 95%;
                    top: -5px;
                    width: 30px;
                    height: 50px;
                    line-height: 50px;
                    cursor: pointer;
                    text-align: center;
  
                    i{
  
                        display: block;
                        margin-top: 25px;
                        border-bottom: 1px solid;
                        position: relative;
                        width: 10px;
                        transition: all 250ms ease-out;
                        &:before{
                            width: 100%;
                            content: "";
                            border-bottom: 1px solid;
                            display: block;
                            position: absolute;
                            top: 0;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
  }
  
  .off-canvas-widget-social{
    a{
        &:first-child{
            margin-left: 0;
        }
        margin: 0 10px;
        font-size: 14px;
  
        @media #{$sm-layout}{
            margin: 0 10px;
        }
  
        &:hover{
            color: $theme-color;
        }
  
    }
  }
  
  /* offcanvas settings */
  
  .offcanvas-settings{
    .offcanvas-navigation{
        & > ul{
            & > li{
                & > a{
                    font-size: 12px;
                    font-weight: 400;
                    padding: 5px 0;
                }
  
                &.menu-item-has-children{
                    .menu-expand{
                        height: 30px;
                        top: -15px;
                        margin-top: 0;
                    }
                }
            }
  
            
        }
  
        ul{
            &.sub-menu{
                & > li{
                   & >  a{
                         padding: 5px 0;
                     }
                 }
             }
        }
  
  
    }
  }
  
  
.transparent-bar {
	left: 0;
	right: 0;
	position: absolute;
	width: 100%;
    z-index: 999;
    
    @media #{$md-layout, $xs-layout, $sm-layout}{
        position: relative;
    }

    .header-top{
        display: none !important;
    }
}


.mobile-menu-middle{
    margin-bottom: 50px;
    .lang-curr-style{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        button{
            background: none;
            border: none;
        }

       
        .title{
            display: inline-block;
            font-weight: 500;
        }

        select{
            border: 1px solid #ddd;
            padding: 5px 0;
        }

        ul{
            li{
                button{
                    font-size: 12px;
                    &:hover, &.active{
                        color: $theme-color;
                    }
                }
            }
        }
    }
}